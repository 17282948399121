import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  templateUrl: './default.html',
})
export class AdminDefaultComponent implements OnInit {
  ViewsToDate = 0;
  SubscribersToDate = 0;
  constructor(private httpClient: HttpClient, public db: AngularFirestore) {
    // AIzaSyBpQEDu8u_hyn4gIxx2lV7VGSn49rA4zTc
  }
  ngOnInit() {
    this.test();
  }
  test = async () => {
    const apiKey = `AIzaSyBpQEDu8u_hyn4gIxx2lV7VGSn49rA4zTc`;
    // tslint:disable-next-line:max-line-length
    const url = `https://content.googleapis.com/youtube/v3/channels?id=UClfSf5LmneLratRdz3hupoQ&part=snippet%2CcontentDetails%2Cstatistics&key=${apiKey}`;
    const result: any = await this.httpClient.get(url).toPromise();
    console.log(result);
    this.ViewsToDate = result.items[0].statistics.viewCount;
    this.SubscribersToDate = result.items[0].statistics.subscriberCount;

    const docRef = this.db.collection('Statistics').doc(new Date().toDateString());
    /* const messagesRef = firebase.database().ref('gloriousLeaders'); */
    docRef.set({
      date: new Date().toDateString(),
      channelViews: result.items[0].statistics.viewCount,
      subscibers: result.items[0].statistics.subscriberCount,
    });
  };
}
