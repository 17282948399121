import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

interface IAdminHighlight {
  episodeNo: number;
  originallyRecorded: string;
  title: string;
  description: string;
  url: string;
}

@Component({
  templateUrl: './highlights.html',
})
export class AdminHighlightsComponent {
  newHighlight: IAdminHighlight = {
    episodeNo: 0,
    originallyRecorded: '',
    title: '',
    description: '',
    url: '',
  };
  addingHighlight = false;
  highlights: any[] = [];
  submitting = false;
  submitted = false;

  constructor(public db: AngularFirestore) {
    const docRef = db.collection('Highlights', (ref) =>
      ref.orderBy('episodeNo', 'desc')
    );
    docRef.get().subscribe((doc) => {
      doc.forEach((doc2) => {
        const data = doc2.data();
        this.highlights.push(data);
      });
    });
  }
  addHighlight = () => {
    this.addingHighlight = !this.addingHighlight;
  };
  submitForm = () => {
    this.submitting = true;
    const docRef = this.db
      .collection('Highlights')
      .doc(`${this.newHighlight.title}`);
    docRef.set(this.newHighlight).then((_) => {
      this.submitted = true;
      setTimeout(() => {
        this.newHighlight = {
          episodeNo: 0,
          originallyRecorded: '',
          title: '',
          description: '',
          url: '',
        };
        this.submitted = false;
        this.submitting = false;
      }, 2000);
    });
  };
}
