import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import * as firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Component({
  selector: `app-root`,
  templateUrl: `./default.html`,
})
export class DefaultComponent implements OnInit {
  test$ = new BehaviorSubject(1);
  loggedInUser$: Observable<firebase.User> = of(firebase.auth().currentUser);
  loggedIn$: Observable<firebase.User | number>;
  currentUserAdmin$ = this.loggedInUser$.pipe(
    map((firebaseUser) => {
      if (firebaseUser && firebaseUser.uid === 'uOnrPSBLENUMdjphLe7FS8Me3dD2') {
        return true;
      } else {
        return false;
      }
    })
  );
  /*   ui: any;
   */ constructor(public afAuth: AngularFireAuth) {
    /* firebaseui.auth
      .AuthUI(firebase.auth())
      .ui.start('#firebaseui-auth-container', {
        signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID]
        // Other config options...
      }); */
    /*     this.ui = new firebaseui.auth.AuthUI(firebase.auth());
     */
  }
  ngOnInit() {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        /*         console.log(firebaseUser);
         */ this.test$.next(2);
      } else {
        /*         console.log('not logged in');
         */ this.test$.next(1);
      }
    });
    this.loggedIn$ = this.isLoggedIn().pipe(
      map((user) => {
        if (user) {
          return user;
        } else {
          return 5;
        }
      })
    );
  }
  isLoggedIn = () => {
    return this.afAuth.authState.pipe(first());
  };
  signOut = () => {
    firebase.auth().signOut();
    location.reload();
    /* this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()); */
  };
  onActivate() {
    window.scroll(0, 0);
  }
}
