import { Component } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';

interface IAdminEpisode {
  episodeNo: number;
  originallyRecorded: string;
  title: string;
  description: string;
  url: string;
}

@Component({
  templateUrl: './episodes.html'
})
export class AdminEpisodesComponent {
  newEpisode: IAdminEpisode = {
    episodeNo: 0,
    originallyRecorded: '',
    title: '',
    description: '',
    url: ''
  };
  addingEpisode = false;
  episodes: any[] = [];
  submitting = false;
  submitted = false;

  constructor(public db: AngularFirestore) {
    const docRef = db.collection('Episodes', ref =>
      ref.orderBy('episodeNo', 'desc')
    );
    docRef.get().subscribe(doc => {
      doc.forEach(doc2 => {
        const data = doc2.data();
        this.episodes.push(data);
      });
    });
  }
  addEpisode = () => {
    this.addingEpisode = !this.addingEpisode;
  };
  submitForm = () => {
    this.submitting = true;
    const docRef = this.db
      .collection('Episodes')
      .doc(`${this.newEpisode.episodeNo}`);
    /* const messagesRef = firebase.database().ref('gloriousLeaders'); */
    docRef.set(this.newEpisode).then(_ => {
      this.submitted = true;
      setTimeout(() => {
        this.newEpisode = {
          episodeNo: 0,
          originallyRecorded: '',
          title: '',
          description: '',
          url: ''
        };
        this.submitted = false;
        this.submitting = false;
        /*         this.db
          .collection('Episodes', ref => ref.orderBy('episodeNo', 'desc'))
          .get()
          .subscribe(doc => {
            doc.forEach(doc2 => {
              const data = doc2.data();
              this.episodes = [];
              this.episodes.push(data);
            });
          }); */
      }, 2000);
    });
  };
}
