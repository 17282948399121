import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore';

interface IClip {
  episodeNo: number;
  title: string;
  description: string;
  originallyRecorded: string;
  src: string;
  link: string;
  highlight: boolean;
  fullEpisode?: string;
}

interface IHighlight {
  title?: string;
  description?: string;
  src?: string;
  link?: string;
}

interface IEpisode {
  episodeNo: number;
  originallyRecorded: string;
  title: string;
  description: string;
  src: SafeResourceUrl;
  link: SafeResourceUrl;
  loaded?: boolean;
  highlights?: IHighlight[];
}

@Component({
  templateUrl: `./home.html`
})
export class HomeComponent {
  latestEpisode: IEpisode;
  episodes: IEpisode[] = [
    {
      episodeNo: 171,
      title: `Satanic Romantic and The Invasion of Sark`,
      description: `What better way to start 2020 than with a Feel Good Story from the Dating life of Nick?
    (Warning, Contains Nightmares)... Also Mozart hated his dad and Guide dogs should'nt go to Prison...`,
      originallyRecorded: `15th January 2020`,
      src: this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/YZXMMlvKAPY`
      ),
      link: this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://youtu.be/YZXMMlvKAPY`
      )
      /*       highlights: [
        {
          title: `Sword Sponsored Space Crimes`,
          description: `This week the tooth pullers make a promise with naturists,
          add sex-modifications to their racist robots, and discuss the first crime committed from space.
          There's also gladiatorial combat, sponsored by Ted the blacksmith.`,
          src: `https://www.youtube.com/embed/etr8icV6Hco`,
          link: `https://youtu.be/etr8icV6Hco`
        },
        {
          title: `Sword Sponsored Space Crimes`,
          description: `This week the tooth pullers make a promise with naturists,
    add sex-modifications to their racist robots, and discuss the first crime committed from space.
    There's also gladiatorial combat, sponsored by Ted the blacksmith.`,
          src: `https://www.youtube.com/embed/etr8icV6Hco`,
          link: `https://youtu.be/etr8icV6Hco`
        }
      ]
 */
    }
  ]; /*  = [
    {
      episodeNo: 152,
      title: `Sword Sponsored Space Crimes`,
      description: `This week the tooth pullers make a promise with naturists,
      add sex-modifications to their racist robots, and discuss the first crime committed from space.
      There's also gladiatorial combat, sponsored by Ted the blacksmith.`,
      originallyRecorded: `28th August 2019`,
      src: `https://www.youtube.com/embed/etr8icV6Hco`,
      link: `https://youtu.be/etr8icV6Hco`,
      highlight: false
    },
  ];
 */
  /*   newEpisode: IClip = {
    episodeNo: 171,
    title: `Satanic Romantic and The Invasion of Sark`,
    description: `What better way to start 2020 than with a Feel Good Story from the Dating life of Nick?
    (Warning, Contains Nightmares)... Also Mozart hated his dad and Guide dogs should'nt go to Prison...`,
    originallyRecorded: `15th January 2020`,
    src: `https://www.youtube.com/embed/YZXMMlvKAPY`,
    link: `https://youtu.be/YZXMMlvKAPY`,
    highlight: false
  };
  clips: IClip[];
 */ constructor(
    public sanitizer: DomSanitizer,
    public db: AngularFirestore
  ) {
    const docRef = db.collection('Episodes', ref =>
      ref.orderBy('episodeNo', 'desc')
    );
    docRef.get().subscribe(doc => {
      const data = doc.docs[0].data();
      this.latestEpisode = {
        episodeNo: data.episodeNo,
        originallyRecorded: data.originallyRecorded,
        title: data.title,
        description: data.description,
        src: this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://www.youtube.com/embed/${data.url}`
        ),
        link: this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://youtu.be/${data.url}`
        ),
        loaded: false
      };
    });
  }
}
