import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import * as firebaseui from 'firebaseui';

@Component({
  templateUrl: `./signIn.html`
})
export class SignInComponent implements OnInit {
  ui: any;
  constructor(public afAuth: AngularFireAuth) {
    /* firebaseui.auth
          .AuthUI(firebase.auth())
          .ui.start('#firebaseui-auth-container', {
            signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID]
            // Other config options...
          }); */
    this.ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
  }
  ngOnInit() {
    this.ui.start('#firebaseui-auth-container', {
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      signInFlow: 'popup',
      signInSuccessUrl: './home'
    });
  }
}
