import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { EpisodesComponent } from './episodes';
import { DefaultComponent } from './default';
import { ContactUsComponent } from './contactUs';
import { AboutUsComponent } from './aboutUs';
import { SignInComponent } from './signIn';
import { AdminDefaultComponent } from 'src/admin/default';
import { AdminEpisodesComponent } from 'src/admin/episodes';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  canActivate,
} from '@angular/fire/auth-guard';
import { HighlightsComponent } from './highlights';
import { AdminHighlightsComponent } from 'src/admin/highlights';
const redirectUnauthorizedToHome = () => redirectUnauthorizedTo(['./home']);
const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  /*   {
    path: '',
    component: DefaultComponent,
    children: [
 */ {
    path: 'home',
    component: HomeComponent,
  },
  { path: 'episodes', component: EpisodesComponent },
  { path: 'highlights', component: HighlightsComponent },
  { path: 'contactUs', component: ContactUsComponent },
  { path: 'aboutUs', component: AboutUsComponent },
  { path: 'signIn', component: SignInComponent },
  {
    path: 'admin',
    component: AdminDefaultComponent,
    ...canActivate(redirectUnauthorizedToHome()),
    children: [
      { path: 'episodes', component: AdminEpisodesComponent },
      { path: 'highlights', component: AdminHighlightsComponent },
    ],
  },
  /*     ]
  }
 */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
