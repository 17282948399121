import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore';

interface IEpisode {
  episodeNo: number;
  originallyRecorded: string;
  title: string;
  description: string;
  src: SafeResourceUrl;
  link: SafeResourceUrl;
  loaded?: boolean;
}
@Component({
  templateUrl: `./episodes.html`
})
export class EpisodesComponent {
  currentStartIndex = 0;
  currentEndIndex = 5;
  allEpisodes: IEpisode[] = [];
  episodes: IEpisode[] = [];
  constructor(public sanitizer: DomSanitizer, public db: AngularFirestore) {
    const docRef = db.collection('Episodes', ref =>
      ref.orderBy('episodeNo', 'desc')
    );
    docRef.get().subscribe(doc => {
      doc.forEach(doc2 => {
        const data = doc2.data();
        this.allEpisodes.push({
          episodeNo: data.episodeNo,
          originallyRecorded: data.originallyRecorded,
          title: data.title,
          description: data.description,
          src: this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://www.youtube.com/embed/${data.url}`
          ),
          link: this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://youtu.be/${data.url}`
          ),
          loaded: false
        });
        this.episodes = this.allEpisodes.slice(0, 5);
      });
    });
  }
  first5 = () => {
    this.currentStartIndex = 0;
    this.currentEndIndex = 5;
    const newList = this.allEpisodes.slice(
      this.currentStartIndex,
      this.currentEndIndex
    );
    newList.forEach(ep => (ep.loaded = false));
    this.episodes = newList;
  };
  last5 = () => {
    this.currentStartIndex = this.allEpisodes.length - 5;
    this.currentEndIndex = this.allEpisodes.length;
    const newList = this.allEpisodes.slice(
      this.currentStartIndex,
      this.currentEndIndex
    );
    newList.forEach(ep => (ep.loaded = false));
    this.episodes = newList;
  };
  next5 = () => {
    if (this.currentStartIndex + 5 <= this.allEpisodes.length) {
      this.currentStartIndex += 5;
      this.currentEndIndex += 5;
      const newList = [
        ...this.allEpisodes.slice(this.currentStartIndex, this.currentEndIndex)
      ];
      newList.forEach(ep => (ep.loaded = false));
      this.episodes = newList;
    }
  };
  previous5 = () => {
    if (this.currentStartIndex - 5 >= 0) {
      this.currentStartIndex -= 5;
      this.currentEndIndex -= 5;
      const newList = this.allEpisodes.slice(
        this.currentStartIndex,
        this.currentEndIndex
      );
      newList.forEach(ep => (ep.loaded = false));
      this.episodes = newList;
    }
  };
  loaded = (episode: IEpisode) => {
    if (episode !== null) {
      episode.loaded = true;
    }
  };
}
