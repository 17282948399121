import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { EpisodesComponent } from './episodes';
import { DefaultComponent } from './default';
import { ContactUsComponent } from './contactUs';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AboutUsComponent } from './aboutUs';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SignInComponent } from './signIn';
import { AdminDefaultComponent } from 'src/admin/default';
import { AdminEpisodesComponent } from 'src/admin/episodes';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { HighlightsComponent } from './highlights';
import { AdminHighlightsComponent } from 'src/admin/highlights';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    HomeComponent,
    EpisodesComponent,
    HighlightsComponent,
    DefaultComponent,
    ContactUsComponent,
    AboutUsComponent,
    SignInComponent,
    AdminDefaultComponent,
    AdminEpisodesComponent,
    AdminHighlightsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    HttpClientModule
  ],
  /*   exports: [NgbCollapseModule],
   */ providers: [],
  bootstrap: [DefaultComponent]
})
export class AppModule {}
