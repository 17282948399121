import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore';

import * as firebase from 'firebase';
import { AngularFireModule } from '@angular/fire';

interface IHighlight {
  episodeNo: number;
  originallyRecorded: string;
  title: string;
  description: string;
  src: SafeResourceUrl;
  link: SafeResourceUrl;
  loaded?: boolean;
}

/* interface IEpisode {
  episodeNo: number;
  originallyRecorded: string;
  title: string;
  description: string;
  src: SafeResourceUrl;
  link: SafeResourceUrl;
  loaded?: boolean;
}
 */

@Component({
  templateUrl: `./highlights.html`,
})
export class HighlightsComponent {
  currentStartIndex = 0;
  currentEndIndex = 5;
  allHighlights: IHighlight[] = [];
  highlights: IHighlight[] = [];
  constructor(public sanitizer: DomSanitizer, public db: AngularFirestore) {
    const docRef = db.collection('Highlights', ref =>
      ref.orderBy('episodeNo', 'desc')
    );
    docRef.get().subscribe((doc) => {
      doc.forEach((doc2) => {
        const data = doc2.data();
        this.allHighlights.push({
          episodeNo: data.episodeNo,
          originallyRecorded: data.originallyRecorded,
          title: data.title,
          description: data.description,
          src: this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://www.youtube.com/embed/${data.url}`
          ),
          link: this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://youtu.be/${data.url}`
          ),
          loaded: false,
        });
        this.highlights = this.allHighlights.slice(0, 5);
      });
    });
  }
  first5 = () => {
    this.currentStartIndex = 0;
    this.currentEndIndex = 5;
    const newList = this.allHighlights.slice(
      this.currentStartIndex,
      this.currentEndIndex
    );
    newList.forEach((ep) => (ep.loaded = false));
    this.highlights = newList;
  }
  last5 = () => {
    this.currentStartIndex = this.allHighlights.length - 5;
    this.currentEndIndex = this.allHighlights.length;
    const newList = this.allHighlights.slice(
      this.currentStartIndex,
      this.currentEndIndex
    );
    newList.forEach((ep) => (ep.loaded = false));
    this.highlights = newList;
  }
  next5 = () => {
    if (this.currentStartIndex + 5 <= this.allHighlights.length) {
      this.currentStartIndex += 5;
      this.currentEndIndex += 5;
      const newList = [
        ...this.allHighlights.slice(this.currentStartIndex, this.currentEndIndex),
      ];
      newList.forEach((ep) => (ep.loaded = false));
      this.highlights = newList;
    }
  }
  previous5 = () => {
    if (this.currentStartIndex - 5 >= 0) {
      this.currentStartIndex -= 5;
      this.currentEndIndex -= 5;
      const newList = this.allHighlights.slice(
        this.currentStartIndex,
        this.currentEndIndex
      );
      newList.forEach((ep) => (ep.loaded = false));
      this.highlights = newList;
    }
  }
  loaded = (episode: IHighlight) => {
    if (episode !== null) {
      episode.loaded = true;
    }
  }
}
