// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAKaxWiLHBXiBwus3oRhvR-Q4Qy66RFfDk',
    authDomain: 'pullingteethpodcast-1d761.firebaseapp.com',
    databaseURL: 'https://pullingteethpodcast-1d761.firebaseio.com',
    projectId: 'pullingteethpodcast-1d761',
    storageBucket: 'pullingteethpodcast-1d761.appspot.com',
    messagingSenderId: '106711854348',
    appId: '1:106711854348:web:88f58f71ac5c9488'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
