import { Component } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  templateUrl: './contactUs.html'
})
export class ContactUsComponent {
  contact = {
    name: '',
    email: '',
    gloriousLeaderName: '',
    gloriousLeaderQualities: '',
    newsLetter: false
  };
  submitting = false;
  submitted = false;
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: 'AIzaSyAKaxWiLHBXiBwus3oRhvR-Q4Qy66RFfDk',
        authDomain: 'pullingteethpodcast-1d761.firebaseapp.com',
        databaseURL: 'https://pullingteethpodcast-1d761.firebaseio.com',
        projectId: 'pullingteethpodcast-1d761',
        storageBucket: 'pullingteethpodcast-1d761.appspot.com',
        messagingSenderId: '106711854348',
        appId: '1:106711854348:web:88f58f71ac5c9488'
      });
    }
  }
  submitForm = () => {
    this.submitting = true;
    const firestore = firebase.firestore();
    const docRef = firestore.collection('WebsiteContactUs');
    /* const messagesRef = firebase.database().ref('gloriousLeaders'); */
    docRef.add(this.contact).then(_ => {
      this.submitted = true;
      setTimeout(() => {
        this.contact = {
          name: '',
          email: '',
          gloriousLeaderName: '',
          gloriousLeaderQualities: '',
          newsLetter: false
        };
        this.submitted = false;
        this.submitting = false;
      }, 3000);
    });
  };
}
